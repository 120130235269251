@font-face {
    font-family: NotoSansJP;
    src: url('https://juchi-web-active-storage-production.s3.ap-northeast-1.amazonaws.com/fonts/NotoSansJP-Regular.woff2')
            format('woff2'),
        url('https://juchi-web-active-storage-production.s3.ap-northeast-1.amazonaws.com/fonts/NotoSansJP-Regular.woff')
            format('woff'),
        url('https://juchi-web-active-storage-production.s3.ap-northeast-1.amazonaws.com/fonts/NotoSansJP-Regular.otf')
            format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: NotoSansJP;
    src: url('https://juchi-web-active-storage-production.s3.ap-northeast-1.amazonaws.com/fonts/NotoSansJP-Medium.woff2')
            format('woff2'),
        url('https://juchi-web-active-storage-production.s3.ap-northeast-1.amazonaws.com/fonts/NotoSansJP-Medium.woff')
            format('woff'),
        url('https://juchi-web-active-storage-production.s3.ap-northeast-1.amazonaws.com/fonts/NotoSansJP-Medium.otf')
            format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: NotoSansJP;
    src: url('https://juchi-web-active-storage-production.s3.ap-northeast-1.amazonaws.com/fonts/NotoSansJP-Bold.woff2')
            format('woff2'),
        url('https://juchi-web-active-storage-production.s3.ap-northeast-1.amazonaws.com/fonts/NotoSansJP-Bold.woff')
            format('woff'),
        url('https://juchi-web-active-storage-production.s3.ap-northeast-1.amazonaws.com/fonts/NotoSansJP-Bold.otf')
            format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

body {
    margin: 0;
    color: #484848;
    font-family: NotoSansJP, sans-serif;
}

* {
    font-family: NotoSansJP, sans-serif;
    font-style: normal;
}
