.loader_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}
